import { AreaMigrationType, AreaPopulationType, AreaSummaryType, AreaType, ForecastType, NewBuildingsProductionPerAreaType } from "../../types";
import { populaApi } from "./apiSlice";

export const areaApiSlice = populaApi.injectEndpoints({
    endpoints: (builder) => ({
        getAreas: builder.query<AreaType, void>({
            query: () => `area`,
        }),
        getAreasMigration: builder.query<AreaMigrationType, void>({
            query: () => `area/parameters`,
        }),
        getAreaProjection: builder.query<ForecastType,{ forecastId:number }>({
            query: ({ forecastId }) => 
                `projection/area-summary/${forecastId}`, 
            providesTags: ['AreaSummary'],
        }),
        getNewBuildingsProductionProjection: builder.query<AreaSummaryType,{ forecastId:number }>({
            query: ({ forecastId }) =>
                `projection/new-buildings-summary/${forecastId}`,
            providesTags: (_, __, { forecastId }) => [{ type: 'BuildingProjectionSummary', id: `${forecastId}` }],
        }),        
        getProductionForecast: builder.query<NewBuildingsProductionPerAreaType,{ forecastId:number }>({
            query: ({ forecastId }) => 
                `projection/new-buildings-table-data/${forecastId}`, 
            providesTags: (_, __, { forecastId }) => [{ type: 'BuildingTableData', id: `${forecastId}` }],
        }),
        getAreaHistory: builder.query<AreaPopulationType,void>({
            query: () => `history/area`,
        }),
    })
})

export const {
    useGetAreasQuery,
    useGetAreasMigrationQuery,
    useGetAreaProjectionQuery,
    useGetNewBuildingsProductionProjectionQuery,
    useGetProductionForecastQuery,
    useGetAreaHistoryQuery,
} = areaApiSlice